import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import jsonDataExample from "../../exampleItems/exampleData.json";
import "./article.scss";
import React from "react";
export default function ArticlePage() {
  const { id } = useParams();
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (id) {
      const findData = jsonDataExample.find((item) => item.id === id);
      if (findData) {
        setData(findData);
      } else {
        console.log("err");
      }
      return;
    }
  }, [id]);

  return (
    <div className="container article_container">
      {data &&
        data?.article.map((item: any, index: number) => (
          <React.Fragment key={index}>
            {item.type === "text" && (
              <p className="articles_text">{item.content}</p>
            )}
            {item.type === "image" && (
              <img src={item.src} alt={item.alt} className="articles_img" />
            )}
          </React.Fragment>
        ))}
    </div>
  );
}
