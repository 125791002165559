import { NavLink, useLocation } from "react-router-dom";
import "./header.scss";
export default function Heder() {
  return (
    <div className="container heder_container">
      <NavLink className="link" to="/">
        <img
          src="/android-chrome-512x512.png"
          alt="site logo"
          className="logo"
        />
      </NavLink>
    </div>
  );
}
