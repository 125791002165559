import TopicCard from "../topics/topicCard";
import AutoPlayCarousel from "components/carousel/carousel";
import data from "../../exampleItems/exampleData.json";
import "./home.scss";
import Hero from "components/baners/hero";

const banerCarusel = data.slice(0, 5);

export default function HomePage(params: any) {
  return (
    <div
      className="container home_container"
    >
      <Hero />
      <div className="home_topic_container">
        <AutoPlayCarousel items={banerCarusel} />

        {data.map((item) => (
          <TopicCard item={item} />
        ))}
      </div>
    </div>
  );
}
