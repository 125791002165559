import { useNavigate } from "react-router-dom";
import "./style.scss";
export default function Hero() {
  const navigation = useNavigate();

  return (
    <div className="baner_container">
      <div className="first_baner">
        <h1 className="title">
          Las Vegas After Dark: When the City Truly Awakens
        </h1>
        <div className="text_container">
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{ width: "100%", height: "100%" }}
            controls={false}
            className="video"
          >
            <source src="/casino.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
}
