import exampleJsonPolicyText from "../../exampleItems/examplePolicyText.json";
import "./policy.scss";
export default function policyPage(params: any) {
  return (
    <div className="container policy_container">
      <h2 className="policy_title">{exampleJsonPolicyText.title}</h2>
      <p
        className="policy_text"
        dangerouslySetInnerHTML={{
          __html: exampleJsonPolicyText.privacyPolicyText,
        }}
      ></p>
      <a href={exampleJsonPolicyText.link} className="policy_link">
        [email protected]
      </a>
    </div>
  );
}
