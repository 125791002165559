import { Carousel } from "rsuite";
import { NavLink } from "react-router-dom";

import "rsuite/dist/rsuite.min.css";

import "./carousel.scss";
export default function AutoPlayCarousel({ items }: any) {
  return (
    <Carousel autoplay className="custom-slider">
      {items.map((item: any, index: number) => (
        <NavLink to={`article/${index + 1}`}>
          <img src={item.img} key={index} alt="s" className="slider_img" />
        </NavLink>
      ))}
    </Carousel>
  );
}
