// TopicsContainer.tsx
import React, { useState } from "react";
import "./topics.scss";
import CategoryButton from "../../button/selectCategory";
import TopicCard from "./topicCard";
import data from "../../exampleItems/exampleData.json";

function TopicsPage() {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([
    "all",
  ]);
  const articlesData = data;

  const filterContentByCategories = () => {
    if (selectedCategories.includes("all")) {
      return articlesData;
    } else {
      return articlesData.filter((item) =>
        selectedCategories.includes(item.category)
      );
    }
  };

  const handleCategorySelect = (category: string) => {
    if (category === "all") {
      setSelectedCategories(["all"]);
    } else {
      if (selectedCategories.includes("all")) {
        setSelectedCategories([category]);
      } else {
        const newCategories = [...selectedCategories];

        if (newCategories.includes(category)) {
          newCategories.splice(newCategories.indexOf(category), 1);
        } else {
          newCategories.push(category);
        }
        if (newCategories.length === 0) {
          newCategories.push("all");
        }

        setSelectedCategories(newCategories);
      }
    }
  };

  const categories = [
    "all",
    "keno",
    "slots",
    "history",
    "brands",
    "bets",
    "aviator",
  ];

  return (
    <div className="container topics_container">
      <div className="topics_list">
        {data.map((item) => (
          <TopicCard key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}

export default TopicsPage;
