import React, { useEffect, useState } from "react";
import * as ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
  useLocation,
} from "react-router-dom";
// static components
import Header from "./components/header/header";
import Nav from "components/nav/nav";
// Pages
import HomePage from "components/pages/home/home";
import ArticlePage from "components/pages/article/article";
import TopicPage from "components/pages/topics/topics";
import PoliciPage from "components/pages/policy/policy";

import "./components/scss/app.scss";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/topic" element={<TopicPage />} />
        <Route path="/article/:id" element={<ArticlePage />} />
        <Route path="/policy" element={<PoliciPage />} />
      </Routes>
      <Nav />
    </BrowserRouter>
  );
}

export default App;
