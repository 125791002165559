import { NavLink } from "react-router-dom";
import "./nav.scss";

export default function Nav() {
  return (
    <nav className="navigation_container ">
      <div className="container">
        <ul className="nav_list">
          <li className="nav_list_item">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "active" : "link")}
            >
              <img
                src="/navImage/home.png"
                alt="home image"
                className="nav_svg_icons"
              />
            </NavLink>
          </li>
          <li className="nav_list_item">
            <NavLink
              to="/topic"
              className={({ isActive }) => (isActive ? "active" : "link")}
            >
              <img
                src="/navImage/articles.png"
                alt="articles image"
                className="nav_svg_icons"
              />
            </NavLink>
          </li>

          <li className="nav_list_item">
            <NavLink
              to="/policy"
              className={({ isActive }) => (isActive ? "active" : "link")}
            >
              <img
                src="/navImage/policy.png"
                alt="policy image"
                className="nav_svg_icons"
              />
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}
